import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import NotificationService from "../../../common/notification-service";

const service = new Service();

//Base Map

export const InitMap = (planningId) => {

    return service
        .post(`${ApiUrl.InitMap}?planningId=${planningId}`)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const CreateBaseMap = (data) => {

    return service
        .post(ApiUrl.CreateBaseMap, data)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
            return res
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

export const UpdateBaseMap = (data) => {
    return service
        .post(ApiUrl.UpdateBaseMap, data)
        .then((res) => {
            //NotificationService.success("Cập nhật thành công");
            return res
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

export const DeleteBasemap = (Id) => {
    return service
        .post(`${ApiUrl.DeleteBaseMap}${Id}`)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

export const UpdateMap = (data) => {
    return service
        .put(ApiUrl.UpdateMap, data)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};
// Show / hide Map

export const ActiveMapById = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service
        .get(ApiUrl.ActiveMapById, params)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};
export const DeActiveMapById = (id) => {
    const params = new URLSearchParams();
    params.append("id", id);
    return service
        .get(ApiUrl.DeactiveMapById, params)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

// Layer Category

export const CreateLayerCategory = (data) => {

    return service
        .post(ApiUrl.CreateLayerCategory, data)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
            return res
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

export const UpdateLayerCategory = (data) => {

    return service
        .post(ApiUrl.UpdateLayerCategory, data)
        .then((res) => {
            // NotificationService.success("Cập nhật thành công");
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

export const DeleteLayerCategory = (id = '') => {

    return service
        .post(`${ApiUrl.DeleteLayerCategory}/${id}`)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
        })

};

export const GetDetailLayerCategory = (id = '') => {

    return service
        .get(`${ApiUrl.GetDetailLayerCategory}/${id}`)
        .then((res) => {
            return res
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

export const UpdateLayerCategoryLevel = (data) => {

    return service
        .post(ApiUrl.UpdateLayerCategoryLevel, data)
        .then((res) => {
            //NotificationService.success("Cập nhật thành công");
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

export const GetLookupLayerCategoryType = (Keyword, PageIndex, PageSize) => {
    const params = new URLSearchParams();
    Keyword && params.append("Keyword", Keyword);
    PageIndex && params.append("PageIndex", PageIndex);
    PageSize && params.append("PageSize", PageSize);
    return service
        .get(ApiUrl.GetLookupLayerCategoryType, params)
        .then((res) => {
            return res
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

//Layer

export const CreateLayer = (data) => {

    return service
        .post(ApiUrl.CreateLayer, data)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
            return res;
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};

export const UpdateLayer = (data) => {

    return service
        .post(ApiUrl.UpdateLayer, data)
        .then((res) => {
            //NotificationService.success("Cập nhật thành công");
        })

};

export const DeleteLayer = (id = '') => {

    return service
        .post(`${ApiUrl.DeleteLayer}/${id}`)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
        })

};

export const UpdateLayerLevel = (data) => {
    return service
    .post(ApiUrl.UpdateLayerLevel, data)
    .then((res) => {
        // NotificationService.success("Cập nhật thành công");
        return res;
    })
    .catch((err) => {
        NotificationService.error(err.errorMessage);
        return false;
    });
};

export const autoCuPlanning = async (layerCategoryId, planningId) => {

    try {
        const res = await service
            .post(`${ApiUrl.AutoCuPlanning}?layerCategoryId=${layerCategoryId}&planningId=${planningId}`);
        console.log('autoCuPlanning');
    } catch (err) {
        return false;
    }
}

export const CreateBaseOnExistLayer = (data) => {

    return service
        .post(ApiUrl.CreateBaseOnExistLayer, data)
        .then((res) => {
            NotificationService.success("Cập nhật thành công");
            return res
        })
        .catch((err) => {
            NotificationService.error(err.errorMessage);
            return false;
        });
};
