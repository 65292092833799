const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
    api: "https://hanoi-api.cgis.asia/",
    domainAdminSide: "http://localhost:3000",
    domainUserSide: "http://localhost:3001",
  },
  production: {
    api: "https://hanoi-api.cgis.asia/",
    DomainUserSite: "https://hanoi.cgis.asia",
    DomainAdminSite: "https://admin.hanoi.cgis.asia",
  },
};

module.exports = apiEnvironment[env];
