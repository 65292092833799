import React from "react";
import "./open-layer.scss";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { Image as ImageLayer } from "ol/layer";
import OSM from "ol/source/OSM";
import ImageWMS from "ol/source/ImageWMS";
import XYZ from "ol/source/XYZ";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as OpenlayerStore from "../../redux/store/openlayer/openlayer.store";
import OpenlayerModelCollect from "../../models/open-layer/props-state";
import BaseMapModels from "../../models/init-map-state/base-map";
import LayerModels from "../../models/init-map-state/layers";
import ControlFunctionType from "./control-function-type/control-function-type";

class OpenlayerMapView extends React.Component<
  OpenlayerModelCollect.OpenlayerMapViewPropsModel,
  OpenlayerModelCollect.OpenlayerMapViewStateModel
> {
  constructor(props: any) {
    super(props);
    this.state = {
      map: null,
      mapContainerStyle: null,
    };
  }

  outSideHandleFunction = (type: string, option: any) => {
    switch (type) {
      case ControlFunctionType.RemoveDisplayLayer:
        {
          const LayerChange: LayerModels.layer_settingsModel = option.layer;
          let indexRemove = -1;
          console.log('okko')
          const listLayerCollection = this.state.map.getLayers();
          listLayerCollection.forEach((event: any, index: number) => {
            if (
              event.values_.source.params_ &&
              event.values_.source.params_.LAYERS ===
              `${LayerChange.table}`
            ) {
              indexRemove = index; 
              console.log('index', index)
              return;
            }
          });
        }
      case ControlFunctionType.ToggleDisplayLayer:
        {
          let indexRemove = -1;
          const LayerChange: LayerModels.layer_settingsModel = option.layer;

          const listLayerCollection = this.state.map.getLayers();
          listLayerCollection.forEach((event: any, index: number) => {
            if (
              event.values_.source.params_ &&
              event.values_.source.params_.LAYERS ===
              `${LayerChange.table}`
            ) {
              indexRemove = index;
              return;
            }
          });

          if (indexRemove !== -1) {
            listLayerCollection.removeAt(indexRemove);
          } else
            listLayerCollection.push(
              new ImageLayer({
                extent: this.props.mapSetting.extent,
                source: new ImageWMS({
                  url: LayerChange.wms,
                  params: {
                    FORMAT: "image/png",
                    VERSION: "1.1.0",
                    STYLES: "",
                    LAYERS: `${LayerChange.table}`,
                  },
                  ratio: 1,
                }),
              })
            );
        }
        break;

      case ControlFunctionType.SelectDisplayBasemap:
        {
          const BaseMapChange: BaseMapModels.base_mapsModel = option.basemap;
          const listLayerCollection = this.state.map.getLayers();

          if (BaseMapChange.baseMapSettingModel.layer_type !== "NONE") {
            listLayerCollection.insertAt(
              0,
              new TileLayer({
                source: new XYZ({
                  url: BaseMapChange.url,
                }),
              })
            );
            listLayerCollection.removeAt(1);
          } else {
            listLayerCollection.item(0).setVisible(false);
          }
        }
        break;
      default: {
        // console.log('have un set type:' + type)
        // console.log(option)
      }
    }
  };

  componentDidMount() {
    const DefaultGroupLayer: any[] = [];
    let listBaseMap = this.props.baseMaps;
    let listLayerGroup: any[] = [];
    let flatLayerList = this.props.flatLayerList;
    flatLayerList.length > 0 && flatLayerList.map((item) => {
      if (item.layer_settings.length > 0) {
        listLayerGroup = [...listLayerGroup, item.layer_settings];
      }
    })
    // let listLayerGroup = this.props.layers.layer_categories;
    listBaseMap.base_maps.map((baseMap: any) => {
      if (baseMap.view_default) {
        DefaultGroupLayer.push(
          new TileLayer({
            source: new XYZ({
              url: baseMap.url,
            }),
          })
        );
      }
    });

    listLayerGroup.map((layerGroup: any) => {
      layerGroup.map((layer: any) => {
        if (layer.is_check)
          DefaultGroupLayer.push(
            new ImageLayer({
              extent: this.props.mapSetting.extent,
              source: new ImageWMS({
                url: layer.wms,
                params: {
                  FORMAT: "image/png",
                  VERSION: "1.1.0",
                  STYLES: "",
                  LAYERS: `${layer.table}`,
                },
                ratio: 1,
              }),
            })
          );
      });
    });

    if (DefaultGroupLayer.length === 0) {
      DefaultGroupLayer.push(
        new TileLayer({
          source: new OSM(),
          visible: false,
        })
      );
    }

    this.props.SetOutSideHandleFunction(this.outSideHandleFunction);
    let mapOpenLayer = new Map({
      target: "map-container-id",
      layers: DefaultGroupLayer,
      view: new View({
        projection: this.props.mapSetting.projection,
        center: this.props.mapSetting.center,
        zoom: this.props.mapSetting.zoom,
      }),
    });
    this.setState({
      map: mapOpenLayer,
    });

    mapOpenLayer.on("moveend", this.onMoveEnd);
  }

  onMoveEnd = (event: any) => {
    let map = event.map;
    let extend = map?.getView().calculateExtent(map.getSize());
    let zoom = map?.getView().getZoom();
    this.props.setExtend(extend);
    this.props.setZoom(Math.round(zoom));
  };

  render() {
    return (
      <div className="h-100" id="map-container-id">
        {/* <MapToolPanel map={this.state.map} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  mapSetting: state.initMap.mapSetting,
  baseMaps: state.initMap.baseMaps,
  layers: state.initMap.layers,
  flatLayerList: state.initMap.flatLayerList,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      SetOutSideHandleFunction: OpenlayerStore.SetHandleOutsideFunction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OpenlayerMapView);
