import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as consultTheCommunityAction from "../../../redux/store/consult-the-community/consult-the-community.store";
import * as viVN from "../../../language/vi-VN.json";
import { NotificationMessageType, Transition } from "../../../utils/configuration";
import { Configs } from "../../../common/config";

//--- Material Control
import {
  Button,
  Tooltip,
  Fab,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";

//--- Material Icon
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from '@material-ui/icons/Refresh';

//--- Component
import ListConsultTheCommunity from "./list-consult-the-community/list-consult-the-community.view";
import AddConsultTheCommunity from "./add-consult-the-community/add-consult-the-community.view";
import EditConsultTheCommunity from "./edit-consult-the-community/edit-consult-the-community.view";
import DeleteDialog from "../../../components/dialog-delete/dialog-delete.view";
import ShowNotification from "../../../components/react-notifications/react-notifications";

function ConsultTheCommunity(props) {
  const { openConsultTheCommunityDialog, handleCloseConsultTheCommunityDialog, classes, planningId, planningName, showConsult, settings } = props;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("startTime");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(Configs.DefaultPageSize);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [dataModel, setDataModel] = useState(null);
  const [consultCommunityId, setConsultCommunityId] = useState(null);
  const [formTemplateId, setFormTemplateId] = useState(null);
  const [data, setData] = useState({
    planning: null,
    title: "",
    status: null,
    hotNew: null,
    startTime: null,
    endTime: null,
  });

  useEffect(() => {
    GetConsultCommunityByPlanning();
  }, []);

  useEffect(() => {
    setClientSetting(settings);
  }, [settings])

  const [clientSetting, setClientSetting] = useState();

  // --- Get List Consult The Community
  const GetConsultCommunityByPlanning = (id = planningId) => {
    consultTheCommunityAction.GetConsultCommunityByPlanning(id).then((res) => {
      res && res.content ? setDataModel(res.content) : setDataModel(null);
    }, (err) => {
      // err && err.errorType && ShowNotification(viVN.Errors[err.errorType], NotificationMessageType.Error);
    });
  };

  //--- Add Dialog
  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const onSuccessAdd = () => {
    GetConsultCommunityByPlanning(planningId);
    handleCloseAddDialog();
    ShowNotification(viVN.Success.CreateSuccess, NotificationMessageType.Success);
  };

  //--- Edit Dialog
  const handleOpenEditDialog = (id) => {
    if (id) {
      setConsultCommunityId(id);
      setOpenEditDialog(true);
    }
  };

  const handleCloseEditDialog = () => {
    setConsultCommunityId(null);
    setOpenEditDialog(false);
  };

  const onSuccessEdit = () => {
    GetConsultCommunityByPlanning(planningId);
    handleCloseEditDialog();
    ShowNotification(viVN.Success.UpdateSuccess, NotificationMessageType.Success);
  };

  //--- Delete Dialog
  const handleOpenDeleteDialog = (id, formTemplateId) => {
    if (id && formTemplateId) {
      setConsultCommunityId(id);
      setFormTemplateId(formTemplateId);
      setOpenDeleteDialog(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setConsultCommunityId(null);
    setFormTemplateId(null);
    setOpenDeleteDialog(false);
  };

  const handleDelete = () => {
    consultCommunityId && formTemplateId && consultTheCommunityAction.DeleteConsultCommunity(consultCommunityId, formTemplateId).then((res) => {
      GetConsultCommunityByPlanning(planningId);
      handleCloseDeleteDialog();
      ShowNotification(viVN.Success.DeleteSuccess, NotificationMessageType.Success);
    }, (err) => {
      GetConsultCommunityByPlanning(planningId);
      handleCloseDeleteDialog();
      err && err.errorType && ShowNotification(viVN.Errors[err.errorType], NotificationMessageType.Error);
    });
  };

  const refresh = () => {
    setData({
      planning: null,
      title: "",
      status: 0,
      hotNew: true,
      startTime: null,
      endTime: null,
    });
    setOrderBy("startTime");
    setOrder("desc");
    GetConsultCommunityByPlanning(planningId);
  }

  return (
    <Dialog
      fullScreen
      open={openConsultTheCommunityDialog}
      onClose={handleCloseConsultTheCommunityDialog}
      TransitionComponent={Transition}
    >
      {
        clientSetting && (
          <AppBar className={classes.appBar} style={{ background: clientSetting.color }}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Xin ý kiến cộng đồng {("(" + planningName + ")")}
              </Typography>
              <Button
                color="inherit"
                onClick={handleCloseConsultTheCommunityDialog}
              >
                <CloseIcon />
              </Button>
            </Toolbar>
          </AppBar>
        )
      }

      <div className="p-4">
        <div className="consult-the-community">
          <div className="d-sm-flex align-items-center justify-content-between mb-3">
            <h1 className="h3 mb-0 text-gray-800">
              <Tooltip title="Refresh">
                <Fab color="primary" aria-label="filter" size="small" onClick={refresh}>
                  <RefreshIcon />
                </Fab>
              </Tooltip>
            </h1>
            {/* {
              !dataModel && ( */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAddDialog}
              startIcon={<AddCircle />}
            >
              Thêm ý kiến
            </Button>
            {/* )} */}

          </div>
          {dataModel && dataModel.map((item, index) => (
            <div className="ctc" key={index}>
              <ListConsultTheCommunity
                isFirst={index == 0}
                dataList={item}
                totalItemCount={item && item.totalItemCount}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                onGetListData={GetConsultCommunityByPlanning}
                editAction={handleOpenEditDialog}
                deleteAction={handleOpenDeleteDialog}
                searchData={data}
                showConsult={showConsult}
                planningName={planningName}
              />

            </div>
          ))}

          {openAddDialog && (
            <AddConsultTheCommunity
              isOpen={openAddDialog}
              dataList={dataModel}
              onClose={handleCloseAddDialog}
              onSuccess={onSuccessAdd}
              planningId={planningId}
              planningName={planningName}
            />
          )}

          {openEditDialog && (
            <EditConsultTheCommunity
              isOpen={openEditDialog}
              consultCommunityId={consultCommunityId}
              onClose={handleCloseEditDialog}
              onSuccess={onSuccessEdit}
            />
          )}

          {openDeleteDialog && (
            <DeleteDialog
              isOpen={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              onSuccess={handleDelete}
              header={"Xóa ý kiến"}
              content={"Bạn có chắc chắn muốn xóa?"}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = state => ({
  settings: state.clientSetting.clientSetting,
})

export default connect(mapStateToProps)(ConsultTheCommunity)