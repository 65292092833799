import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//--- Styles
import "./introduce.scss";

//--- Component
import ListIntroduce from "./list-introduce/list-introduce.view";
import AddIntroduce from "./add-introduce/add-introduce.view";
import EditIntroduce from "./edit-introduce/edit-introduce.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";

//--- Redux store
import * as introduceAction from "../../redux/store/introduces/introduce.store"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Introduce(props) {
    const classes = useStyles();
    const [introduceModels, setIntroduceModels] = useState();
    const [introduceId, setIntroduceId] = useState();

    const { register, handleSubmit } = useForm({ mode: "all", reValidateMode: "onBlur" });

    useEffect(() => {
        GetListAll();
    }, [])

    const GetListAll = () => {
        introduceAction.GetListAll().then(res => {
            if (res && res.content) {
                setIntroduceModels(res.content);
            }
        }).catch(err => console.log(err));
    }

    //--- Dialog
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleOpenEditDialog = (introduceId) => {
        setIntroduceId(introduceId);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    //--- Filter
    const [filterSection, setFilterSection] = useState(null);

    const handleClickFilter = (event) => {
        setFilterSection(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setFilterSection(null);
    };

    const ShowNotificationError = (messages) => {
        ShowNotification(messages, NotificationMessageType.Error)
    }

    const openFilter = Boolean(filterSection);
    const idFilter = openFilter ? 'popoverSlider' : undefined;

    const refresh = () => {
        GetListAll();
    }

    return (
        <div className="slider">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h1 className="h3 mb-0 text-gray-800">
                    Điều khoản sử dụng
                </h1>
                {/*<Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenAddDialog}
                    startIcon={<AddCircle />}>
                    Thêm Tin
                    </Button>*/}
            </div>
            {introduceModels ? <ListIntroduce
                introduceModels={introduceModels}
                GetListAll={GetListAll}
                editAction={handleOpenEditDialog}

            /> : ""}
            {openAddDialog && (
                <AddIntroduce
                    isOpen={openAddDialog}
                    onClose={handleCloseAddDialog}
                    onSuccess={handleCloseAddDialog}
                    ShowNotificationError={ShowNotificationError}
                    GetListAll={GetListAll}
                />
            )}

            {openEditDialog && (
                <EditIntroduce
                    isOpen={openEditDialog}
                    onClose={handleCloseEditDialog}
                    onSuccess={handleCloseEditDialog}
                    introduceId={introduceId}
                    ShowNotificationError={ShowNotificationError}
                    GetListAll={GetListAll}
                />
            )}

            {openDeleteDialog && (
                <DeleteDialog
                    isOpen={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    header={"Xác nhận xóa"}
                    content={"Bạn có chắc chắn muốn xóa?"}
                />
            )}
        </div>
    );
}
