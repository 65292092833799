import React from "react";
import "./layers.scss";
import { Container, Draggable } from "react-smooth-dnd";
import ControlFunctionOpenlayerType from "../../../../components/open-layer/control-function-type/control-function-type";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as InitMapStore from "../../../../redux/store/init-map/init-map.store";
import * as mapAction from "../../../../redux/store/planning-map/planning-map.store";
import { GetFlatLayerList } from '../../../../redux/store/init-map/mapping-data';
import ModalLayerSetting from "./modal-setting/modal-setting";
import ModalEditSettingLayer from "./modal-edit-layer/modal-edit-layer";
import ConfirmModalView from "../../../../components/confirm-modal/confirm-modal";
import OpenlayerCommand from "../../../../components/open-layer/control-function-type/control-function-type";

//--- Material Control
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { Tooltip, IconButton, makeStyles, Typography } from "@material-ui/core";

//--- Material Icon
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LayersIcon from "@material-ui/icons/Layers";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import EditIcon from "@material-ui/icons/Edit";
import TuneIcon from "@material-ui/icons/Tune";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import CloseIcon from "@material-ui/icons/Close";
import $ from 'jquery';
import NotificationService from "../../../../common/notification-service";

class RenderLayerControlView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmModal: false,
      confirmModalTitle: "",
      confirmModalHandleAccept: null,
      dataSourcLayerSelected: "",
      listLayers: this.props.data,
      isOpenSettingGroupLayers: false,
      parentId: 0,
      addParentId: 0,
      addParentLevel: 0,
      currentLayerLevel: 0,
      currentEditGroupId: 0,
      isCreateNewGroupLayers: false,
      isOpenSettingLayer: false,
      isCreateNewLayer: false,
      groupIndexSelected: null,
      layerIndexSelected: null,
      layerEditingId: 0,
      groupLayersName: "",
      idGroupLayerSelected: null,
      indexGroupSelected: null,
      hasShowWarningGroupLayerSetting: false,
      layerSettingViewType: 0, // view - 3, filter - 2, setting - 1, data source -0
    };
  }

  onSetDataSourceLayerSelected = (dataSource) =>
    this.setState({ dataSourcLayerSelected: dataSource });

  getChildPayloadGrouplayer = (index, GroupLayers) => {
    console.log('GroupLayers', index, GroupLayers)
    if (!GroupLayers) {
      return this.getLayerData.getLayerGroupByIndex(index);
    } else {
      return {
        GroupLayers: GroupLayers,
        GroupLayer: GroupLayers.children[index],
        hasParent: true,
      }
      // return GroupLayers.children[index];
    }
  };


  getChildPayloadLayer = (indexLayer, indexGroup, GroupId) => {
    // return this.getLayerDataByLayerId(layerId)
    return this.getLayerByGroupIdAndLayerIndex(GroupId, indexLayer);
  };

  getLayerByGroupIdAndLayerIndex = (GroupId, indexLayer) => {
    let flatLayerList = this.props.flatLayerList;
    let layer = null;
    flatLayerList.map(item => {
      if (item.id === GroupId) layer = item.layer_settings[indexLayer]
    })
    return layer
  }

  onDropGroupLayer = (event) => {
    console.log('event', event)
    let data = {
      lstLevelLayerCategory: []
    };
    if (!event.payload.hasParent) {
      console.log('321')
      const ListGroupLayers = this.getLayerData.getListGroupLayers();
      ListGroupLayers.splice(event.removedIndex, 1);
      ListGroupLayers.splice(event.addedIndex, 0, event.payload);
      for (let i = 0; i < ListGroupLayers.length; i++) {
        ListGroupLayers[i]['level'] = i;
        data.lstLevelLayerCategory.push({
          id: ListGroupLayers[i]['id'],
          level: i,
        })
      }
      this.autoUpdateLayerData();
      mapAction.UpdateLayerCategoryLevel(data).then((res) => {

      })
    } else {
      console.log('123')
      let GroupLayers = event.payload.GroupLayers;
      GroupLayers.children.splice(event.removedIndex, 1);
      GroupLayers.children.splice(event.addedIndex, 0, event.payload.GroupLayer);
      console.log('dakhsgdas', GroupLayers)
      let categoryList = this.props.layerData.layer_categories;
      this.updateGroupLayerData(categoryList, GroupLayers.id, GroupLayers);
      for (let i = 0; i < GroupLayers.children.length; i++) {
        GroupLayers.children[i]['level'] = i;
        data.lstLevelLayerCategory.push({
          id: GroupLayers.children[i]['id'],
          level: i,
        })
      }
      this.autoUpdateLayerData();
      mapAction.UpdateLayerCategoryLevel(data).then((res) => {

      })
    }

  };

  updateGroupLayerData = (categoryList, groupId, groupName) => {
    let isDone = false;
    if (Array.isArray(categoryList) && categoryList.length > 0 && isDone == false) {
      for (let i = 0; i < categoryList.length; i++) {
        if (categoryList[i].id == groupId) {
          categoryList[i] = groupName;
          isDone = true;
        }

        if (Array.isArray(categoryList[i].children) && categoryList[i].children.length > 0 && isDone == false) {
          categoryList[i].children = this.updateGroupNameToLayerData(categoryList[i].children, groupId, groupName);
        }
      }
    }

    return categoryList;
  }

  onDropLayer = (event, groupId) => {
    //let categoryList = this.props.layerData.layer_categories;
    console.log('event', event)
    if (event.removedIndex != null && event.addedIndex != null) {
      const ListGroupLayer = this.getLayerSettingListByGroupId(groupId);
      console.log('trc', ListGroupLayer)

      const editGroupLayer = [...ListGroupLayer];
      if (Array.isArray(editGroupLayer) && editGroupLayer.length > 0) {
        const removeItem = ListGroupLayer[event.removedIndex];
        const addItem = ListGroupLayer[event.addedIndex];
        editGroupLayer.splice(event.removedIndex, 1);
        editGroupLayer.splice(event.addedIndex, 0, event.payload);
        let data = {
          lstLevelLayer: []
        }
        for (let i = 0; i < editGroupLayer.length; i++) {
          editGroupLayer[i]['level'] = i;
          data.lstLevelLayer.push({
            id: editGroupLayer[i]['id'],
            level: i,
          })
        }
        console.log('sau', editGroupLayer)
        this.updateSortLayerList(groupId, editGroupLayer)
        mapAction.UpdateLayerLevel(data).then((res) => {
          if (!res) {
            this.updateSortLayerList(groupId, ListGroupLayer)
          }
        })
      }
    }
  };

  updateSortLayerList = (groupId, updateLayerList) => {
    let categoryList = this.props.layerData.layer_categories;
    let newCategory = this.updateLayerCategory(categoryList, groupId, updateLayerList);
    this.props.layerData.layer_categories = newCategory;
    console.log('newCategory', newCategory)
    this.autoUpdateLayerData();
  }

  updateLayerCategory = (categoryList, groupId, updateLayerList) => {
    let isDone = false;
    if (Array.isArray(categoryList) && categoryList.length > 0 && isDone == false) {
      for (let i = 0; i < categoryList.length; i++) {
        if (categoryList[i].children == null) {
          categoryList[i].children = [];
        }
        if (categoryList[i].id == groupId && isDone == false) {
          categoryList[i].layer_settings = updateLayerList;
          isDone = true;
        }

        if (Array.isArray(categoryList[i].children) && categoryList[i].children.length > 0 && isDone == false) {
          categoryList[i].children = this.updateLayerCategory(categoryList[i].children, groupId, updateLayerList);
        }
      }
    }

    return categoryList;
  }

  onClickAddNewGroup = (event, parentId, level) => {
    event.stopPropagation();
    this.setState({
      groupLayersName: "",
      isOpenSettingGroupLayers: true,
      isCreateNewGroupLayers: true,
    });
    if (parentId > 0) {
      this.setState({
        parentId: parentId,
        addParentId: parentId,
        addParentLevel: level
      });
    }
  };

  onClickSettingGroupLayer = (event, indexGroup, groupId) => {
    event.stopPropagation();
    this.state.currentEditGroupId = groupId;
    this.setState({
      // currentEditGroupId: groupId,
      isOpenSettingGroupLayers: true,
      isCreateNewGroupLayers: false,
      indexGroupSelected: indexGroup,
      groupLayersName: this.getGroupLayerNameByGroupId(groupId),
    });
    console.log('gfdhgfdshgfdshgdk', this.props.layerData.layer_categories)
    // console.log('gfdhgfdshgfdshgdkfdsfdsfsd', this.props.layerData)
  };

  getGroupLayerNameByGroupId = (groupId) => {
    let flatLayerList = this.props.flatLayerList;
    console.log('flatLayerList', flatLayerList)
    let groupName = null;
    if (Array.isArray(flatLayerList) && flatLayerList.length > 0) {
      flatLayerList.map((layer) => {
        if (layer.id == groupId) {
          groupName = layer.folder_name;
        }
      })
    }
    return groupName;
  }

  onClickDeleteGrouplayer = () => {
    let groupId = this.state.currentEditGroupId;
    mapAction.DeleteLayerCategory(groupId).then(() => {
      let categoryList = this.props.layerData.layer_categories;
      categoryList = this.removeGroupLayer(categoryList, groupId);
      this.props.layerData.layer_categories = categoryList;
      this.autoUpdateLayerData();
    })
    this.setState({
      isOpenSettingGroupLayers: false,
    });
  };

  removeGroupLayer = (layerData, groupId) => {
    let returnData = [];
    if (layerData && Array.isArray(layerData) && layerData.length > 0) {
      for (let i = 0; i < layerData.length; i++) {
        if (layerData[i].id != groupId) {
          returnData.push(layerData[i]);
        }
      }
    }

    if (Array.isArray(returnData) && returnData.length > 0) {
      for (let i = 0; i < returnData.length; i++) {
        if (Array.isArray(returnData[i].children) && returnData[i].children.length > 0) {
          returnData[i].children = this.removeGroupLayer(returnData[i].children, groupId)
        }
      }
    }

    return returnData;
  }

  onClickOpenAddNewLayer = (event, groupLayerIndex, groupId) => {
    event.stopPropagation();
    this.setState({
      isOpenSettingLayer: true,
      isCreateNewLayer: true,
      groupIndexSelected: groupLayerIndex,
      parentId: groupId,
    });
  };

  onClickEditLayer = (event, layerId, groupId) => {
    event.stopPropagation();
    this.setState({
      isOpenSettingLayer: true,
      isCreateNewLayer: false,
      layerEditingId: layerId,
      idGroupLayerSelected: groupId,
    });
  };

  handleChangeSettingLayerType = (event, newValue) => {
    this.setState({
      layerSettingViewType: newValue,
    });
  };

  markLayerChecked = (category, objectId) => {
    let layerSettingList = category.layer_settings;
    let childrenList = category.children;

    if (Array.isArray(childrenList) && childrenList.length > 0) {
      for (let j = 0; j < childrenList.length; j++) {
        childrenList[j] = this.markLayerChecked(childrenList[j], objectId);
      }
    }

    if (Array.isArray(layerSettingList) && layerSettingList.length > 0) {
      for (let i = 0; i < layerSettingList.length; i++) {
        if (layerSettingList[i].id == objectId) {
          layerSettingList[i].is_check = !layerSettingList[i].is_check;
          break;
        }
      }
    }
    category.children = childrenList;
    category.layer_settings = layerSettingList;
    return category;
  }

  onClickCheckBoxLayer = (layerObject, indexLayer, indexGroup) => {
    let layerChecked = { ...layerObject, is_check: !layerObject.is_check }
    console.log('layerObject', layerChecked)

    let params = formatLayer(layerChecked);
    mapAction.UpdateLayer(params).then(() => {
      let categoryList = this.props.layerData.layer_categories;
      for (let i = 0; i < categoryList.length; i++) {
        categoryList[i] = this.markLayerChecked(categoryList[i], layerObject.id);
      }
      this.props.layerData.layer_categories = categoryList;
      this.autoUpdateLayerData();
      this.toggleDisplayLayerAction(layerObject);
    })
    // let categoryList = this.props.layerData.layer_categories;
    // for (let i = 0; i < categoryList.length; i++) {
    //   categoryList[i] = this.markLayerChecked(categoryList[i], layerObject.id);
    // }
    // this.props.layerData.layer_categories = categoryList;
    // this.autoUpdateLayerData();
    // this.toggleDisplayLayerAction(layerObject);
  };

  RemoveDisplayLayer = (layerObject) => {
    this.props.selectToggleLayerFunction(
      ControlFunctionOpenlayerType.RemoveDisplayLayer,
      {
        layer: layerObject,
      }
    );
  }

  toggleDisplayLayerAction = (layerObject) => {
    this.props.selectToggleLayerFunction(
      ControlFunctionOpenlayerType.ToggleDisplayLayer,
      {
        layer: layerObject,
      }
    );
  };

  handleAddNewLayer = (newLayer) => {
    console.log("newLayer", newLayer)
    // if (newLayer.is_check) {
    //   this.toggleDisplayLayerAction(newLayer);
    // }
    // this.props.layerData.layer_categories = this.pushNewLayerToParentLayer(
    //   this.props.layerData.layer_categories, newLayer
    // );
    let params = formatLayer(newLayer, this.state.parentId)
    mapAction.CreateLayer(params).then(res => {
      if (res && res.content) {
        if (newLayer.is_check) {
          this.toggleDisplayLayerAction(newLayer);
        }
        this.props.layerData.layer_categories = this.pushNewLayerToParentLayer(
          this.props.layerData.layer_categories, { ...newLayer, id: res.content.id, layer_category_id: this.state.parentId }
        );
        this.autoUpdateLayerData();
      }
    })
  };

  pushNewLayerToParentLayer = (settingList, newLayer) => {
    let parentId = this.state.parentId;
    if (Array.isArray(settingList) && settingList.length > 0) {
      for (let i = 0; i < settingList.length; i++) {
        if (settingList[i].id == parentId) {
          settingList[i].layer_settings.push(newLayer);
        } else {
          if (Array.isArray(settingList[i].children) && settingList[i].children.length > 0) {
            settingList[i].children = this.pushNewLayerToParentLayer(settingList[i].children, newLayer);
          }
        }
      }
    }
    return settingList;
  }

  updateEditedLayerToLayerDataList = (dataList, dataLayerEdited, layerId) => {
    if (Array.isArray(dataList) && dataList.length > 0) {
      for (let i = 0; i < dataList.length; i++) {
        let isUpdate = 0;
        let settingList = dataList[i].layer_settings;
        if (Array.isArray(settingList) && settingList.length > 0) {
          for (let j = 0; j < settingList.length; j++) {
            if (settingList[j].id == layerId) {
              isUpdate = 1;
              settingList[j] = dataLayerEdited;
            }
          }
          dataList[i].layer_settings = settingList;
        }
        if (isUpdate == 0 && Array.isArray(dataList[i].children) && dataList[i].children.length > 0) {
          dataList[i].children = this.updateEditedLayerToLayerDataList(dataList[i].children, dataLayerEdited, layerId);
        }
      }
    }
    return dataList;
  }

  handleClickAcceptSettingGrouplayer = () => {
    let currentEditGroupId = this.state.currentEditGroupId;
    if (!this.state.groupLayersName.trim()) {
      if (!this.state.hasShowWarningGroupLayerSetting)
        this.setState({ hasShowWarningGroupLayerSetting: true });
      return;
    }
    let formData = new FormData();
    formData.append("MapSettingId", this.getLayerData.getMapSettingId());
    formData.append("FolderLabel", this.state.groupLayersName);
    formData.append("FolderName", this.state.groupLayersName);

    if (this.state.isCreateNewGroupLayers) {
      let newGroup = {};
      let flatLayerList = this.props.flatLayerList;

      if (this.state.addParentId > 0) {
        formData.append("Level", this.getLengthArrLayerCategoryByParentId(this.state.addParentId));
        formData.append("ParentId", this.state.addParentId);
        mapAction.CreateLayerCategory(formData).then(res => {
          if (res) {
            newGroup = {
              id: res.content.id,
              folder_label: this.state.groupLayersName,
              folder_name: this.state.groupLayersName,
              level: this.getLengthArrLayerCategoryByParentId(this.state.addParentId),
              layer_settings: [],
              parent_id: this.state.addParentId,
            }
            this.props.layerData.layer_categories = this.addNewGroupToLayerData(
              this.props.layerData.layer_categories,
              this.state.addParentId,
              newGroup
            );
            flatLayerList[newGroup.id] = newGroup;
            this.autoUpdateLayerData();
            // this.props.UpdateFlatLayerList(flatLayerList);
          }
        })
      } else {
        formData.append("Level", this.props.layerData.layer_categories.length);
        mapAction.CreateLayerCategory(formData).then(res => {
          if (res) {
            console.log('ok')
            newGroup = {
              id: res.content.id,
              folder_label: this.state.groupLayersName,
              folder_name: this.state.groupLayersName,
              level: this.props.layerData.layer_categories.length,
              layer_settings: [],
              map_setting_id: this.getLayerData.getLayerData().map_id,
            }
            this.getLayerData.getListGroupLayers().push(newGroup);
            flatLayerList[newGroup.id] = newGroup;
            this.autoUpdateLayerData();
            // this.props.UpdateFlatLayerList(flatLayerList);
          }
        })
        // newGroup = {
        //   folder_label: this.state.groupLayersName,
        //   folder_name: this.state.groupLayersName,
        //   id: 0,
        //   layer_settings: [],
        //   level: 0,
        //   map_setting_id: this.getLayerData.getLayerData().map_id,
        // }
        // this.getLayerData.getListGroupLayers().push(newGroup);
      }
    } else {
      formData.append("id", this.state.currentEditGroupId);
      formData.append("Level", this.state.indexGroupSelected);
      formData.append("ParentId", this.state.addParentId);
      mapAction.UpdateLayerCategory(formData).then(res => {
        let categoryList = this.props.layerData.layer_categories;
        this.props.layerData.layer_categories = this.updateGroupNameToLayerData(
          categoryList,
          this.state.currentEditGroupId,
          this.state.groupLayersName
        );
        this.autoUpdateLayerData();
        NotificationService.success("Cập nhật thành công");
      })
    }

    this.autoUpdateLayerData();
    this.setState({
      isOpenSettingGroupLayers: false,
      hasShowWarningGroupLayerSetting: false,
    });
  };

  addNewGroupToLayerData = (categoryList, groupId, newGroup) => {
    let isDone = false;
    if (Array.isArray(categoryList) && categoryList.length > 0 && isDone == false) {
      for (let i = 0; i < categoryList.length; i++) {
        if (categoryList[i].id == groupId) {
          if (categoryList[i].children == null) {
            categoryList[i].children = [];
          }
          categoryList[i].children.push(newGroup);
          isDone = true;
        }

        if (Array.isArray(categoryList[i].children) && categoryList[i].children.length > 0 && isDone == false) {
          categoryList[i].children = this.addNewGroupToLayerData(categoryList[i].children, groupId, newGroup);
        }
      }
    }

    return categoryList;
  }

  updateGroupNameToLayerData = (categoryList, groupId, groupName) => {
    let isDone = false;
    if (Array.isArray(categoryList) && categoryList.length > 0 && isDone == false) {
      for (let i = 0; i < categoryList.length; i++) {
        if (categoryList[i].id == groupId) {
          categoryList[i].folder_name = groupName;
          categoryList[i].folder_label = groupName;
          isDone = true;
        }

        if (Array.isArray(categoryList[i].children) && categoryList[i].children.length > 0 && isDone == false) {
          categoryList[i].children = this.updateGroupNameToLayerData(categoryList[i].children, groupId, groupName);
        }
      }
    }

    return categoryList;
  }

  autoUpdateLayerData = () => {
    this.props.UpdateLayers({ ...this.getLayerData.getLayerData() });
    let data = GetFlatLayerList({ ...this.getLayerData.getLayerData() })
    this.props.UpdateFlatLayerList(data);
  };


  handleDeleteLayer = (event, layerId, groupLayerId, indexLayer) => {
    this.RemoveDisplayLayer(this.getLayerByGroupIdAndLayerIndex(groupLayerId, indexLayer))
    // console.log('1234', this.getLayerByGroupIdAndLayerIndex(groupLayerId, indexLayer))
    mapAction.DeleteLayer(layerId).then(() => {
      this.props.layerData.layer_categories = this.removeLayerFromLayerData(
        this.props.layerData.layer_categories, layerId
      );
      // this.RemoveDisplayLayer(this.getLayerByGroupIdAndLayerIndex(groupLayerId, indexLayer))
      this.autoUpdateLayerData();
    })
  };

  getLayerData = {
    getMapSettingId: () => this.props.layerData.id,
    getLayerData: () => this.props.layerData,
    getListGroupLayers: () => this.props.layerData.layer_categories,
    getLayerGroupByIndex: (index) =>
      this.props.layerData.layer_categories[index],
    getLayerGroupArrayLayerByIndex: (index) =>
      this.props.layerData.layer_categories[index].layer_settings,
  };

  getLengthArrLayerCategoryByParentId = (id) => {
    let flatLayerList = this.props.flatLayerList;
    let length = 0;
    flatLayerList.map(item => {
      if (item.children && item.id === id) {
        length = item.children.length
      }
    })
    return length;
  }

  removeLayerFromLayerData = (dataList, layerId) => {
    if (Array.isArray(dataList) && dataList.length > 0) {
      for (let i = 0; i < dataList.length; i++) {
        let isUpdate = 0;
        let settingList = dataList[i].layer_settings;
        if (Array.isArray(settingList) && settingList.length > 0) {
          for (let j = 0; j < settingList.length; j++) {
            if (settingList[j].id == layerId) {
              isUpdate = 1;
              settingList.splice(j, 1);
            }
          }
          dataList[i].layer_settings = settingList;
        }
        if (isUpdate == 0 && Array.isArray(dataList[i].children) && dataList[i].children.length > 0) {
          dataList[i].children = this.removeLayerFromLayerData(dataList[i].children, layerId);
        }
      }
    }
    return dataList;
  }

  getLayerSettingListByGroupId = (groupId) => {
    let flatLayerList = this.props.flatLayerList;
    let returnSettingList = [];
    if (Array.isArray(flatLayerList) && flatLayerList.length > 0) {
      flatLayerList.map((layer) => {
        let layerSettingList = layer.layer_settings;
        if (layer.id == groupId) {
          returnSettingList = layerSettingList;
        }
      })
    }
    return returnSettingList;
  }

  getLayerDataByLayerId = (layerId) => {
    let flatLayerList = this.props.flatLayerList;
    let returnLayer = [];
    if (Array.isArray(flatLayerList) && flatLayerList.length > 0) {
      flatLayerList.map((layer) => {
        let layerSettingList = layer.layer_settings;
        if (Array.isArray(layerSettingList) && layerSettingList.length > 0) {
          layerSettingList.map((data) => {
            if (data.id == layerId) {
              if (data.display == null) {
                data.display = data.display_name;
              }
              if (data.filter == null) {
                data.filter = data.filter_name;
              }
              returnLayer = data;
            }
          })
        }
      })
    }
    return returnLayer;
  }

  handleSaveEditLayer = (dataLayerEdited) => {
    console.log('dataLayerEdited', dataLayerEdited)
    dataLayerEdited.display_name = null
    dataLayerEdited.filter_name = null
    let params = formatLayer(dataLayerEdited)
    mapAction.UpdateLayer(params).then(() => {
      let originalLayer = this.getLayerDataByLayerId(this.state.layerEditingId);
      if (dataLayerEdited.is_check != originalLayer.is_check) {
        this.toggleDisplayLayerAction(dataLayerEdited);
      }

      this.props.layerData.layer_categories = this.updateEditedLayerToLayerDataList(
        this.props.layerData.layer_categories, dataLayerEdited, this.state.layerEditingId
      );
      this.autoUpdateLayerData();
      NotificationService.success("Cập nhật thành công");
    })
  };

  openConfirmDeleteGroupLayer = () => {
    this.setState({
      openConfirmModal: true,
      confirmModalTitle: "Xóa nhóm layer",
      confirmModalHandleAccept: () => this.onClickDeleteGrouplayer(),
    });
  };

  openConfirmDeleteLayer = (event, layerId, groupLayerId, indexLayer) => {
    this.setState({
      openConfirmModal: true,
      confirmModalTitle: "Xóa layer",
      confirmModalHandleAccept: () =>
        this.handleDeleteLayer(event, layerId, groupLayerId, indexLayer),
    });
  };

  renderLayerName = (GroupLayer, indexGroup) => {
    return (
      <AccordionSummary>
        <div className="layer-group">
          <div className="layer-group-text">
            <span>{GroupLayer.folder_label}</span>
          </div>
          <div className="layer-group-action">
            <Tooltip title="Tạo nhóm layer mới">
              <IconButton
                onClick={(event) => this.onClickAddNewGroup(event, GroupLayer.id, GroupLayer.level)}
              >
                <AddCircleIcon fontSize="small" className="text-success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Thêm layer mới">
              <IconButton
                onClick={(event) =>
                  this.onClickOpenAddNewLayer(
                    event,
                    indexGroup,
                    GroupLayer.id
                  )
                }
              >
                <PlaylistAddIcon
                  fontSize="small"
                  className="text-success"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Thiết đặt">
              <IconButton
                onClick={(event) =>
                  this.onClickSettingGroupLayer(
                    event,
                    indexGroup,
                    GroupLayer.id
                  )
                }
              >
                <TuneIcon
                  fontSize="small"
                  color="primary"
                />
              </IconButton>
            </Tooltip>
          </div>
          <div className="cb"></div>
        </div>
      </AccordionSummary>
    )
  }

  renderLayerDetail = (GroupLayer, indexGroup) => {
    return (
      <AccordionDetails>
        <Container
          getChildPayload={(event) =>
            this.getChildPayloadLayer(event, indexGroup, GroupLayer.id)
          }
          onDrop={(event) =>
            this.onDropLayer(event, GroupLayer.id)
          }
          dragClass="drag-state-custom-ddb"
          groupName="Grouplayers"
        >
          {GroupLayer.layer_settings &&
            GroupLayer.layer_settings.map(
              (layer, indexLayer) => (
                <Draggable key={`${layer.id}_${indexLayer}`}>
                  <div className="layer-item" id={`layer-item-${GroupLayer.id}-${indexLayer}`} value={layer.id}>
                    <div className="layer-item-check">
                      <input
                        type="checkbox"
                        id={`checkbox-layer-index-${indexLayer}`}
                        checked={layer.is_check}
                        onChange={() => { }}
                        onClick={() =>
                          this.onClickCheckBoxLayer(
                            layer,
                            indexLayer,
                            indexGroup
                          )
                        }
                      />
                    </div>
                    <div className="layer-item-text">
                      <span title={layer.name} >{layer.name}</span>
                    </div>
                    <div className="layer-item-action">
                      <Tooltip title="Chỉnh sửa">
                        <IconButton
                          onClick={(event) =>
                            this.onClickEditLayer(
                              event,
                              layer.id,
                              GroupLayer.id,
                            )
                          }
                        >
                          <EditIcon
                            color="primary"
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Xóa layer">
                        <IconButton
                          onClick={(event) =>
                            this.openConfirmDeleteLayer(
                              event,
                              layer.id,
                              GroupLayer.id,
                              indexLayer,
                            )
                          }
                        >
                          <DeleteIcon
                            fontSize="small"
                            className="text-danger"
                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="cb"></div>
                  </div>
                </Draggable>
              )
            )}
        </Container>
      </AccordionDetails>
    )
  }

  increaseLayerLevel = () => {
    this.state.currentLayerLevel = this.state.currentLayerLevel + 1
  }
  reduceLayerLevel = () => {
    if (this.state.currentLayerLevel > 0) {
      this.state.currentLayerLevel = this.state.currentLayerLevel - 1;
    } else {
      this.state.currentLayerLevel = 0;
    }
  }
  renderLayerGroup = (GroupLayers, indexGroup) => {
    return (
      // <div className="layer-box">
      <Draggable key={`${indexGroup}-${GroupLayers.id}`}>
        <Accordion>
          {GroupLayers && (
            this.renderLayerName(GroupLayers, indexGroup)
          )}
          {
            GroupLayers.children && GroupLayers.children.length > 0 && this.increaseLayerLevel()
          }
          {
            GroupLayers.children && GroupLayers.children.length > 0 && (
              <Container
                getChildPayload={(event) => this.getChildPayloadGrouplayer(event, GroupLayers, indexGroup)}
                onDrop={(event) => this.onDropGroupLayer(event, GroupLayers)}
                dragClass="drag-state-custom-ddb"
              >
                {GroupLayers.children.map((child, childIndex) => {
                  return this.renderLayerGroup(child, childIndex);
                })}
              </Container>
            )

          }
          {GroupLayers && (
            this.renderLayerDetail(GroupLayers, indexGroup)
          )}
          {
            GroupLayers.children && GroupLayers.children.length == 0 && this.reduceLayerLevel()
          }
        </Accordion>
      </Draggable>
      // </div>
    )
  }

  renderCreateLayerGroup = () => {
    return (
      <Accordion>
        <AccordionSummary
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
          className="position-relative base-map-header-summary"
        >
          <div
            className="row w-100 p-0 m-0"
            style={{ alignItems: "baseline" }}
          >
            <div className="col-8 p-0 m-0">
              <AccountTreeIcon className="icon-layer mr-2" />
              Layers
            </div>
            <div className="col-4 text-right p-0 m-0">
              <Tooltip title="Tạo nhóm layer mới">
                <IconButton
                  onClick={(event) => this.onClickAddNewGroup(event, 0)}
                >
                  <AddCircleIcon fontSize="small" className="text-success" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="d-block">
          <Divider />

          {this.props.layerData.haveData &&
            this.props.layerData.layer_categories.length === 0 && (
              <p className="text-center mt-3 mb-0">
                Chưa có nhóm layer nào được tạo
              </p>
            )}

          <Container
            getChildPayload={this.getChildPayloadGrouplayer}
            onDrop={this.onDropGroupLayer}
            dragClass="drag-state-custom-ddb"
          >
            {this.props.layerData.haveData &&
              this.props.layerData.layer_categories.map(
                (GroupLayers, indexGroup) => (
                  this.state.currentLayerLevel = 1,
                  this.renderLayerGroup(GroupLayers, indexGroup)
                )
              )}
          </Container>
        </AccordionDetails>
      </Accordion>
    )
  }

  renderEditLayerGroup = () => {
    return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        onClose={() => this.setState({ isOpenSettingGroupLayers: false })}
        aria-labelledby="base-map-add-alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="dialog-create-base-map"
        open={this.state.isOpenSettingGroupLayers}
      >
        <DialogTitle
          id="base-map-add-alert-dialog-title"
          onClose={() => this.setState({ isOpenSettingGroupLayers: false })}
        >
          {this.state.isCreateNewGroupLayers ? "Thêm Nhóm mới" : "Thiết lập"}
        </DialogTitle>
        <DialogContent className="content-custom-container" dividers>
          <InputLabel className="text-dark">Tên nhóm</InputLabel>
          <TextField
            helperText={
              this.state.hasShowWarningGroupLayerSetting &&
                !this.state.groupLayersName.trim()
                ? "Không được để trống"
                : ""
            }
            error={
              this.state.hasShowWarningGroupLayerSetting &&
              !this.state.groupLayersName.trim()
            }
            value={this.state.groupLayersName}
            onChange={(event) =>
              this.setState({ groupLayersName: event.target.value })
            }
            fullWidth
            variant="outlined"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => this.setState({ isOpenSettingGroupLayers: false })}
            color="error"
          >
            Đóng
          </Button>

          {!this.state.isCreateNewGroupLayers && (
            <Button
              variant="contained"
              onClick={() => this.openConfirmDeleteGroupLayer()}
              color="secondary"
            >
              <DeleteIcon className="mr-1" />
              Xóa nhóm
            </Button>
          )}

          <Button
            variant="contained"
            onClick={() => this.handleClickAcceptSettingGrouplayer()}
            color="primary"
          >
            Chấp nhận
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderSettingLayer = () => {
    return (
      <Dialog
        maxWidth="lg"
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth={true}
        id="dialog-create-base-map"
        open={this.state.isOpenSettingLayer}
        onClose={() => this.setState({ isOpenSettingLayer: false })}
      >
        <DialogTitle disableTypography id="base-map-add-alert-dialog-title">
          <Typography variant="h6">Tạo layer</Typography>
          <IconButton
            aria-label="close"
            onClick={() => this.setState({ isOpenSettingLayer: false })}
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#9e9e9e",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          className="content-custom-container"
          id="content-custom-container-setting-layer"
          dividers
        >
          {this.state.isCreateNewLayer
            ? this.state.isOpenSettingLayer && (
              <ModalLayerSetting
                parentId={this.state.parentId}
                planningId={this.props.planningId}
                handleAddNewLayer={(newLayer) =>
                  this.handleAddNewLayer(newLayer)
                }
                closeModal={() =>
                  this.setState({ isOpenSettingLayer: false })
                }
              />
            )
            : this.state.isOpenSettingLayer && (
              <ModalEditSettingLayer
                setLayerData={(data) => this.handleSaveEditLayer(data)}
                layerData={this.getLayerDataByLayerId(this.state.layerEditingId)}
                closeModal={() =>
                  this.setState({ isOpenSettingLayer: false })
                }
              />
            )}
        </DialogContent>
      </Dialog>
    )
  }

  renderConfirmModal = () => {
    return (
      <ConfirmModalView
        open={this.state.openConfirmModal}
        handleClose={() => this.setState({ openConfirmModal: false })}
        title={this.state.confirmModalTitle}
        handleAccept={this.state.confirmModalHandleAccept}
      />
    )
  }

  render() {
    return (
      <div className="left-menu-layer-control-container mt-2">
        {this.renderCreateLayerGroup()}
        {this.renderEditLayerGroup()}
        {this.renderSettingLayer()}
        {this.renderConfirmModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layerData: state.initMap.layers,
  layerMapSetting: state.initMap.layerMapSetting,
  flatLayerList: state.initMap.flatLayerList,
  controlOpenlayer: state.openLayer.handleOutSideFunction,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      UpdateLayers: InitMapStore.UpdateLayer,
      UpdateFlatLayerList: InitMapStore.UpdateFlatLayerList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderLayerControlView);

const formatLayer = (layer, layerCategoryId) => {
  let params = {
    displayName: layer.display,
    filterName: layer.filter,
    layerCategoryId: layerCategoryId,
    name: layer.name,
    // geoTiff: ,
    geoLayerName: layer.name,
    wms: layer.wms,
    wmsExternal: layer.wms_external,
    // sourceExternal: layer.source_external,
    table: layer.table,
    level: layer.level,
    zindex: layer.z_index,
    minZoom: layer.min_zoom,
    maxZoom: layer.max_zoom,
    isChecked: layer.is_check,
    layerType: layer.layer_type,
  }
  if (layer.id) params.id = layer.id;
  if (layerCategoryId) {
    params.layerCategoryId = layerCategoryId;
  } else if (layer.layer_category_id) {
    params.layerCategoryId = layer.layer_category_id;
  }
  if (layer.files) params.files = layer.files;
  if (layer.select_mutil) params.selectMulti = layer.select_mutil;
  if (layer.style) params.style = layer.style;
  if (layer.document_upload_id) params.documentUploadId = layer.document_upload_id;
  return params;
}