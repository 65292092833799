import React, { useState, useEffect } from "react";
import { Configs } from "../../common/config";
import { useForm } from "react-hook-form";

//--- Material Control
import {
    Button,
    TextField,
    IconButton,
    Tooltip,
    Popover,
    Select,
    MenuItem,
    Checkbox,
    Fab,
} from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ButtonGroup from '@material-ui/core/ButtonGroup';

//--- Styles
import "./notification.scss";

//--- Material Icon
import DeleteCircle from "@material-ui/icons/DeleteForever"
import AddCircle from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from '@material-ui/icons/Refresh';

//--- Component
import ListNotification from "./list-notification/list-notification.view";
import AddNotification from "./add-notification/add-notification.view";
import EditNotification from "./edit-notification/edit-notification.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";

//--- Notifications
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";

//--- Redux store
import * as notificationAction from "../../redux/store/notification/notification.store"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Notification(props) {
    const classes = useStyles();
    const [notificationModels, setNotificationModels] = useState();
    const [totalItemCount, setTotalItemCount] = useState();
    const [searchData, setSearchData] = useState({
        "categoryId":14,
        "title": "",
        "description": "",
        "status":2
    });
    const [notificationId, setNotificationId] = useState();
    const [notificationxmin, setNotificationxmin] = useState();
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("modifiedDate");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);

    const [isDelete, setIsDelete] = useState(0);
    const [isShowTrash, setIsShowTrash] = useState(false);

    const { register, handleSubmit } = useForm({ mode: "all", reValidateMode: "onBlur" });

    useEffect(() => {
        GetListAll();
    }, [])

    const GetListAll = (categoryId = 14, title, isHot = undefined, isFeature = undefined, isDelete = 0, status = undefined,
        pageIndex = 1, pageSize, sortExpression) => {
        setPage(pageIndex - 1);
        notificationAction.GetListAll(categoryId, title, isHot, isFeature, isDelete, status, pageIndex, pageSize, sortExpression).then(res => {
            if (res && res.content) {
                setNotificationModels(res.content.items);
                setTotalItemCount(res.content.totalItemCount);
            }
        }).catch(err => console.log(err));
    }

    //--- Dialog
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleOpenEditDialog = (notificationId) => {
        setNotificationId(notificationId);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleOpenDeleteDialog = (notificationId, notificationxmin) => {
        setNotificationId(notificationId);
        setNotificationxmin(notificationxmin);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    //--- Filter
    const [filterSection, setFilterSection] = useState(null);

    const handleClickFilter = (event) => {
        setFilterSection(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setFilterSection(null);
    };

    const ShowNotificationError = (messages) => {
        ShowNotification(messages, NotificationMessageType.Error)
    }

    const openFilter = Boolean(filterSection);
    const idFilter = openFilter ? 'popoverSlider' : undefined;
    const handleRestore = (id) => {
        notificationAction.RestoreNotification(id).then(
            (res) => {
                if (res && res.content && res.content.status) {
                    GetListAll(searchData.categoryId, searchData.title, undefined, undefined, isDelete, searchData.status, 1, rowsPerPage, orderBy + " " + order);
                    ShowNotification(
                        viVN.Success.NotificationRestoreSuccess,
                        NotificationMessageType.Success
                    );
                }
            },
            (err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
            }
        );
    }
    const handleDelete = () => {
        notificationAction.DeleteNotification(notificationId, notificationxmin).then(
            (res) => {
                if (res && res.content && res.content.status) {
                    GetListAll(searchData.categoryId, searchData.title, undefined, undefined, isDelete, searchData.status, 1, rowsPerPage, orderBy + " " + order);
                    handleCloseDeleteDialog();
                    ShowNotification(
                        viVN.Success.NotificationDeleteSuccess,
                        NotificationMessageType.Success
                    );
                }
            },
            (err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
            }
        );
    };

    const handelChangeFeature = (notificationId, value) => {
        alert("change " + notificationId + " feature to" + value);
    }
    const handleClearAllField = () => {
        refresh();
        setFilterSection(null);
    }

    const onSubmit = async (data) => {
        await GetListAll(searchData.categoryId, searchData.title, undefined, undefined, isDelete, searchData.status, 1, rowsPerPage, orderBy + " " + order);
        handleCloseFilter()
    }

    const refresh = () => {
        setSearchData({
            "categoryId": 1,
            "title": "",
            "description": "",
            "status": 2
        });
        setOrderBy("modifiedDate")
        setOrder("desc");
        GetListAll(1, "", undefined, undefined, isDelete, undefined, 1, rowsPerPage, orderBy + " " + order);
    }

    const handleChangeTrash = (event) => {
        setIsShowTrash(event.target.checked);
        setIsDelete(event.target.checked ? 1 : 0);
        GetListAll(searchData.categoryId, searchData.title, undefined, undefined, (event.target.checked ? 1 : 0), searchData.status, 1, rowsPerPage, orderBy + " " + order);
    };

    //const handleChangeStatus = (event) => {
    //    setStatus(event.target.value === 2 ? undefined : event.target.value);
    //    setSearchData({ [event.target.name]: event.target.value });
    //}

    const handleChangeSearchForm = (event) =>
    {
        setSearchData({...searchData, [event.target.name]: event.target.value });
    }

    const handleEmptyTrash = () => {
        notificationAction.EmptyTrash().then(
            (res) => {
                if (res && res.content && res.content.status) {
                    GetListAll(searchData.categoryId, searchData.title, undefined, undefined, isDelete, searchData.status, 1, rowsPerPage, orderBy + " " + order);
                    //handleCloseDeleteDialog();
                    ShowNotification(
                        viVN.Success.NotificationDeleteSuccess,
                        NotificationMessageType.Success
                    );
                }
            },
            (err) => {
                err &&
                    err.errorType &&
                    ShowNotification(
                        viVN.Errors[err.errorType],
                        NotificationMessageType.Error
                    );
            }
        );
    }
    return (
        <div className="slider">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <h1 className="h3 mb-0 text-gray-800">
                    Quá trình lập quy hoạch
          <Tooltip title="Tìm kiếm">
                        <Fab
                            color="primary"
                            aria-label="filter"
                            size="small"
                            className="ml-2"
                            onClick={handleClickFilter}
                            aria-describedby={idFilter}
                        >
                            <SearchIcon />
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Làm mới">
                        <Fab color="primary" aria-label="filter" size="small" onClick={refresh} className="ml-2">
                            <RefreshIcon />
                        </Fab>
                    </Tooltip>
                    <span>&nbsp;&nbsp;</span>
                    
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isShowTrash}
                                onChange={handleChangeTrash}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Thùng rác"
                    />
                    <Popover
                        id={idFilter}
                        open={openFilter}
                        anchorEl={filterSection}
                        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
                        onClose={handleCloseFilter}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <div className="p-3" style={{ width: '30rem' }}>
                            <div className="text-right border-bottom mb-3 pb-2">
                                <IconButton
                                    aria-label="close"
                                    size="small"
                                    onClick={handleCloseFilter}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </div>
                            <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label className="text-dark">Tiêu đề</label>
                                    <TextField className="w-100" name="title" value={searchData.title} onChange={handleChangeSearchForm} inputRef={register} />
                                </div>
                                <div className="form-group">
                                    <label className="text-dark">Mô tả</label>
                                    <TextField className="w-100" name="description" value={searchData.description} onChange={handleChangeSearchForm} inputRef={register} />
                                </div>
                                <div className="form-group">
                                    <label className="text-dark">Trạng thái</label> &nbsp;&nbsp;
                                    <Select
                                        name="status"
                                        value={searchData.status}
                                        onChange={handleChangeSearchForm}
                                    >
                                        <MenuItem value={2}>----------------</MenuItem>
                                        <MenuItem value={1}>Kích hoạt</MenuItem>
                                        <MenuItem value={0}>Không kích hoạt</MenuItem>
                                    </Select>
                                </div>
                                <div className="border-top">
                                    <div className="row">
                                        <div className="col-12 text-right mt-3">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={onSubmit}
                                            >
                                                <SearchIcon fontSize="small" /> Tìm kiếm
                      </Button>
                                            <Button variant="contained" className="ml-2" onClick={handleClearAllField}>
                                                <ClearAllIcon fontSize="small" /> Bỏ lọc
                      </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Popover>
                </h1>
                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">

                    {
                        isShowTrash && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleEmptyTrash}
                                startIcon={<DeleteCircle />}>
                                Xóa thùng rác
                            </Button>
                            )
                    }
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenAddDialog}
                        startIcon={<AddCircle />}>
                        Thêm mới
                    </Button>
                </ButtonGroup>
            </div>
            {notificationModels ? <ListNotification
                totalItemCount={totalItemCount}
                notificationModels={notificationModels}
                GetListAll={GetListAll}
                title={searchData.title}
                categoryId={searchData.categoryId}
                isDelete={isDelete}
                editAction={handleOpenEditDialog}
                deleteAction={handleOpenDeleteDialog}
                restoreAction={handleRestore}
                changeFeatureAction={handelChangeFeature}
                setOrder={setOrder} setOrderBy={setOrderBy}
                setPage={setPage} setRowsPerPage={setRowsPerPage}
                order={order} orderBy={orderBy}
                page={page} rowsPerPage={rowsPerPage}

            /> : ""}
            {openAddDialog && (
                <AddNotification
                    isOpen={openAddDialog}
                    onClose={handleCloseAddDialog}
                    onSuccess={handleCloseAddDialog}
                    ShowNotificationError={ShowNotificationError}
                    GetListAll={GetListAll}
                    rowsPerPage={rowsPerPage}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                />
            )}

            {openEditDialog && (
                <EditNotification
                    isOpen={openEditDialog}
                    onClose={handleCloseEditDialog}
                    onSuccess={handleCloseEditDialog}
                    notificationId={notificationId}
                    ShowNotificationError={ShowNotificationError}
                    GetListAll={GetListAll}
                    rowsPerPage={rowsPerPage}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                />
            )}

            {openDeleteDialog && (
                <DeleteDialog
                    isOpen={openDeleteDialog}
                    onClose={handleCloseDeleteDialog}
                    onSuccess={handleDelete}
                    header={"Xác nhận xóa"}
                    content={"Bạn có chắc chắn muốn xóa?"}
                />
            )}
        </div>
    );
}
