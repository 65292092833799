import React, { useEffect, useState } from 'react';
import InitMapDataView from './init-map';
import { connect } from 'react-redux';
import * as mapStore from '../../redux/store/planning-map/planning-map.store';

//--- Material Icon
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import SaveDataToDataBaseButton from '../init-map-data/save-button/save-button';
import ResetMapDataLocalButton from '../init-map-data/reset-data-button/reset-data-button';
import DeleteButton from '../init-map-data/delete-button/delete-button';
import ShowHideMap from '../init-map-data/show-hide-map/show-hide-map';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        //backgroundColor: '#00923F'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    headerTooltip: {
        maxWidth: 'none',
        fontSize: '0.85rem',
    },
}));

function MapView(props) {
    const classes = useStyles();
    const location = useLocation();
    const { settings } = props;
    const arr = location.pathname.split("/");
    const planningId = arr[2];

    const [clientSetting, setClientSetting] = useState();
    const [mapId, setMapId] = useState(arr[3]);
    // const [planningId, setPlanningId] = useState();
    useEffect(() => {
        setClientSetting(settings);
    }, [settings])
    useEffect(() => {
        if(!mapId) {
            mapStore.InitMap(planningId).then(res => {
                if(res && res.content) {
                    setMapId(res.content)
                }
            })
        }
    },[])
    

    return (
        <>
            <AppBar className={classes.appBar} >
                <Toolbar>
                    {/* <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton> */}
                    <Tooltip title={(props.mapSetting.name || "Bản đồ chưa đặt tên")} classes={{ tooltip: classes.headerTooltip }}>
                        <Typography variant="h6" className={classes.title + ' text-truncate'}>
                            Map Creator ({(props.mapSetting.name || "Bản đồ chưa đặt tên")})
                        </Typography>
                    </Tooltip>
                    <ShowHideMap mapId={mapId} is_active={props.mapSetting.is_active} mapSetting={props.mapSetting} />
                </Toolbar>
            </AppBar>
            <div className="h-100">
                <InitMapDataView mapId={mapId} planningId={planningId} />
            </div>
        </>
    )
}
const mapStateToProps = state => ({
    mapSetting: state.initMap.mapSetting,
    settings: state.clientSetting.clientSetting,
})

export default connect(mapStateToProps, null)(MapView)
