/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  Button,
  Tooltip,
  Popover,
  Fab,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";

//--- Material Icon
import AddCircle from "@material-ui/icons/AddCircle";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";

//--- Component
import DeleteDialog from "../../../components/dialog-delete/dialog-delete.view";
import ListRecordsManagement from "../records-management/list-records-management/list-records-management.view";
import AddRecordsManagement from "../records-management/add-records-management/add-records-management.view";
import EditRecordsManagement from "../records-management/edit-records-management/edit-records-management.view";
import CreatMapView from "../../create-map/create-map";
import { Configs } from "../../../common/config";

import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as appActions from "../../../core/app.store";
import * as planningAction from "../../../redux/store/planning/planning.store";

//--- Material Icon
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import RefreshIcon from "@material-ui/icons/Refresh";

const useStyles = makeStyles((theme) => ({
  popover: {
    maxHeight: "300px",
  },
  box: {
    minWidth: "15rem",
  },
}));

function RecordsManagement(props) {
  const { showLoading } = props;
  const classes = useStyles();

  const [mapIdSelected, setMapIdSelected] = useState(null);
  const [planningIdSelected, setPlanningIdSelected] = useState(null);
  const [planningNameSelected, setPlanningNameSelected] = useState("");
  const [openCreatMapModal, setOpenCreatMapModal] = useState(false);
  const [order, setOrder] = useState(Configs.DefaultOrder);
  const [orderBy, setOrderBy] = useState(Configs.DefaultOrderBy);
  const [page, setPage] = useState(1);
  const [pageSizeDefault, setPageSize] = useState(Configs.DefaultPageSize);

  const [planning, setPlanning] = useState(null);
  const [planningId, setPlanningId] = useState(null);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [filterSection, setFilterSection] = useState(null);
  const [openSearch, setOpenSearch] = useState(false);
  const [showConsult, setShowConsult] = useState(false);
  const [searchSection, setSearchSection] = useState(null);
  const [dataModel, setDataModel] = useState(null);
  const [planningName, setPlanningName] = useState("");
  const [cgisId, setCgisId] = useState(0);

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    onGetListPlanning();
    const showConsult = new URLSearchParams(props.location.search).get(
      "showConsult"
    );
    const planningIdURL = new URLSearchParams(props.location.search).get(
      "planningId"
    );
    const planningNameURL = new URLSearchParams(props.location.search).get(
      "planningName"
    );
    if (showConsult) {
      setPlanningId(planningIdURL);
      setShowConsult(true);
      setPlanningName(planningNameURL);
    }
  }, []);

  const onGetListPlanning = (
    pageIndex = page,
    pageSize = pageSizeDefault,
    sortExpression = Configs.DefaultSortExpression,
    name = planningName || ""
  ) => {
    showLoading(true);
    setPage(pageIndex > 0 ? pageIndex : 0);
    planningAction
      .GetListPlanning(pageIndex, pageSize, sortExpression, name.trim())
      .then(
        (res) => {
          res && res.content && setDataModel(res.content);
          res && res.content && setCgisId(res.content.cgisId);

          res && res.content && localStorage.setItem('cgisId', res.content.cgisId); //should wrapper into a common service to localStorage
          showLoading(false);
        },
        (err) => {
          showLoading(false);
        }
      );
  };

  //--- Add Dialog
  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const onSuccessAdd = () => {
    onGetListPlanning(1, pageSizeDefault, orderBy ? orderBy + " " + order : "");
    handleCloseAddDialog();
    ShowNotification(
      viVN.Success.CreateSuccess,
      NotificationMessageType.Success
    );
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  //--- Edit Dialog
  const handleOpenEditDialog = (id) => {
    if (id) {
      setPlanningId(id);
      setOpenEditDialog(true);
    }
  };

  const onSuccessEdit = () => {
    onGetListPlanning(
      page > 0 ? page : 1,
      pageSizeDefault,
      orderBy ? orderBy + " " + order : ""
    );
    handleCloseEditDialog();
    ShowNotification(
      viVN.Success.UpdateSuccess,
      NotificationMessageType.Success
    );
  };

  const handleCloseEditDialog = () => {
    setPlanningId(null);
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (id) => {
    if (id) {
      setPlanningId(id);
      setOpenDeleteDialog(true);
    }
  };

  const onDeletePlanning = () => {
    if (!planningId) return;
    showLoading(true);
    planningAction.DeletePlanning(planningId).then(
      (res) => {
        onGetListPlanning(
          1,
          pageSizeDefault,
          orderBy ? orderBy + " " + order : ""
        );
        handleCloseDeleteDialog();
        ShowNotification(
          viVN.Success.DeleteSuccess,
          NotificationMessageType.Success
        );
      },
      (err) => {
        handleCloseDeleteDialog();
        showLoading(false);
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      }
    );
  };

  const handleCloseDeleteDialog = () => {
    setPlanningId(null);
    setOpenDeleteDialog(false);
  };

  //--- Filter
  const handleClickFilter = (event) => {
    setFilterSection(event.currentTarget);
  };
  const handleClickSearch = (event) => {
    setOpenSearch(true);
    setSearchSection(event.currentTarget);
  };

  const handleCloseSearch = () => {
    setSearchSection(null);
    setOpenSearch(false);
  };

  const handleCloseFilter = () => {
    setFilterSection(null);
    setOpenSearch(false);
  };

  const openFilter = Boolean(filterSection);
  const idFilter = openFilter ? "popoverSlider" : undefined;
  const idFilterSearch = openSearch ? "poppoverSearchSlide" : undefined;
  //--- Map modal
  const handleOpenCreatMapModal = (mapId, planningId, planningName) => {
    setPlanningNameSelected(planningName);
    setMapIdSelected(mapId);
    setPlanningIdSelected(planningId);
    setOpenCreatMapModal(true);
  };

  const handleCloseCreatMapModal = () => {
    onGetListPlanning();
    setOpenCreatMapModal(false);
  };

  //--- Header Default
  const headCellDefault = [
    {
      id: "planningName",
      hideSortIcon: false,
      label: "Tên đồ án QH",
      visibleColumn: true,
    },
    //{
    //  id: "planningCode",
    //  hideSortIcon: false,
    //  label: "Mã quy hoạch",
    //  visibleColumn: true,
    //},
    {
      id: "categoryName",
      hideSortIcon: false,
      label: "Loại quy hoạch",
      visibleColumn: true,
    },
    //{
    //  id: "place",
    //  hideSortIcon: false,
    //  label: "Địa điểm",
    //  visibleColumn: true,
    //},
    {
      id: "mapLink",
      hideSortIcon: true,
      label: "Link bản đồ",
      visibleColumn: true,
    },
    
    {
      id: "governmentName",
      hideSortIcon: false,
      label: "Cơ quan ban hành",
      visibleColumn: true,
    },
    {
      id: "planningAgency",
      hideSortIcon: false,
      label: "Cơ quan phê duyệt",
      visibleColumn: true,
    },
    {
      id: "planningUnit",
      hideSortIcon: false,
      label: "Đơn vị lập quy hoạch",
      visibleColumn: true,
    },
    {
      id: "agencySubmitted",
      hideSortIcon: false,
      label: "Cơ quan trình",
      visibleColumn: true,
    },
    //{
    //  id: "consultingUnit",
    //  hideSortIcon: false,
    //  label: "Đơn vị tư vấn",
    //  visibleColumn: true,
    //},
    {
      id: "investor",
      hideSortIcon: false,
      label: "Chủ đầu tư",
      visibleColumn: true,
    },
    {
      id: "documentTypeName",
      hideSortIcon: false,
      label: "Loại hồ sơ",
      visibleColumn: true,
    },
    {
      id: "modifiedDate",
      hideSortIcon: false,
      label: "Cập nhật mới nhất",
      visibleColumn: true,
      },
      {
          id: "planningStatusName",
          hideSortIcon: false,
          label: "Trạng thái",
          visibleColumn: true,
      },
    {
      id: "consultTheCommunity",
      hideSortIcon: false,
      label: "Xin ý kiến",
      visibleColumn: true,
    },
    {
      id: "announced",
      hideSortIcon: false,
      label: "Công bố",
      visibleColumn: true,
    },
    {
      id: "planningRelated",
      hideSortIcon: false,
      label: "Quy hoạch liên quan",
      visibleColumn: true,
    },
    // {
    //   id: "documentSettings",
    //   hideSortIcon: false,
    //   label: "Thiết lập HS",
    //   visibleColumn: true,
    // },
    { id: "actions", hideSortIcon: true, label: "", visibleColumn: true },
  ];

  //--- Visible checkbox default
  const visibleCheckboxDefault = {
    planningName: true,
    planningCode: true,
    planningTypeName: true,
    place: true,
    mapLink: true,
    planningStatusName: true,
    planningLevelName: true,
    planningAgency: true,
    planningUnit: true,
    agencySubmitted: true,
    consultingUnit: true,
    investor: true,
    documentTypeName: true,
    modifiedDate: true,
    consultTheCommunity: true,
    announced: true,
    planningRelated: true,
    documentSettings: true,
    actions: true,
  };

  //--- Visible Column
  const [visibleColumn, setVisibleColumn] = useState(visibleCheckboxDefault);

  //--- Header cell
  const [headCells, setHeadCells] = useState(
    headCellDefault.map((value) => {
      return Object.assign({}, value);
    })
  );

  const [headCellsTemp, setHeadCellsTemp] = useState(
    headCellDefault.map((value) => {
      return Object.assign({}, value);
    })
  );

  const handleChangeVisibleCheckbox = (event) => {
    setVisibleColumn({
      ...visibleColumn,
      [event.target.name]: event.target.checked,
    });

    headCellsTemp.map((headItem, headIndex) => {
      if (headItem.id === event.target.name) {
        headItem.visibleColumn = event.target.checked;
      }
    });

    setHeadCellsTemp(headCellsTemp);
  };
  const handleClearAllField = () => {
    setPlanningName("");
  };
  const refresh = () => {
    setPlanningName("");
    onGetListPlanning(1, pageSizeDefault, Configs.DefaultSortExpression, "");
  };
  const onSubmit = (data) => {
    onGetListPlanning(
      1,
      pageSizeDefault,
      orderBy + " " + order,
      data.planningName
    );
    handleCloseFilter();
    handleCloseSearch();
  };

  const handleChangeVisibleColumn = () => {
    setHeadCells(
      headCellsTemp.map((value) => {
        return Object.assign({}, value);
      })
    );
    handleCloseFilter();
  };

  return (
    <div className="consult-the-community">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        {/* <SearchRecordManagement /> */}
        <h1 className="h3 mb-0 text-gray-800">
          Dự án quy hoạch
          <Tooltip title="Tìm kiếm">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={handleClickSearch}
              className="ml-2"
              aria-describedby={idFilterSearch}
            >
              <FilterListIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Refresh">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={refresh}
              className="ml-2"
            >
              <RefreshIcon />
            </Fab>
          </Tooltip>
          <Popover
            id={idFilterSearch}
            open={openSearch}
            anchorEl={searchSection}
            onClose={handleCloseSearch}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <div className="p-3" style={{ width: "30rem" }}>
              <div className="text-right border-bottom mb-3 pb-2">
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={handleCloseSearch}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
              <form id="formSearch" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="text-dark">Tên đồ án quy hoạch</label>
                  <TextField
                    fullWidth
                    name="planningName"
                    value={planningName}
                    onChange={(e) => {
                      setPlanningName(e.target.value);
                    }}
                    inputRef={register}
                  />
                </div>
                <div className="border-top">
                  <div className="row">
                    <div className="col-12 text-right mt-3">
                      <Button variant="contained" color="primary" type="submit">
                        <SearchIcon fontSize="small" /> Tìm kiếm
                      </Button>
                      <Button
                        variant="contained"
                        className="ml-2"
                        onClick={handleClearAllField}
                      >
                        <ClearAllIcon fontSize="small" /> Bỏ lọc
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Popover>
          <Tooltip title="Ẩn/hiện cột">
            <Fab
              color="primary"
              aria-label="filter"
              size="small"
              onClick={handleClickFilter}
              className="ml-2"
            >
              <VisibilityOffIcon />
            </Fab>
          </Tooltip>
          <Popover
            id={idFilter}
            open={openFilter}
            anchorEl={filterSection}
            onClose={handleCloseFilter}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box className={classes.box}>
              <div className="p-3">
                <div className="d-flex justify-content-between align-items-center border-bottom">
                  <Typography variant="h6">Ẩn/hiện cột</Typography>
                  <IconButton size="small" onClick={handleCloseFilter}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
                <FormGroup>
                  {headCellsTemp && headCellsTemp.length > 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={headCellsTemp.visibleColumn}
                          onChange={handleChangeVisibleCheckbox}
                          name={headCellsTemp.id}
                          color="primary"
                        />
                      }
                      label={headCellsTemp.label}
                      className="mb-0"
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={visibleColumn.planningCode}
                        onChange={handleChangeVisibleCheckbox}
                        name="planningCode"
                        color="primary"
                      />
                    }
                    label="Mã quy hoạch"
                    className="mb-0"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={visibleColumn.planningName}
                        onChange={handleChangeVisibleCheckbox}
                        name="planningName"
                        color="primary"
                      />
                    }
                    label="Tên đồ án QH"
                    className="mb-0"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={visibleColumn.planningTypeName}
                        onChange={handleChangeVisibleCheckbox}
                        name="planningTypeName"
                        color="primary"
                      />
                    }
                    label="Loại quy hoạch"
                    className="mb-0"
                  />
                </FormGroup>
                <div className="d-flex border-top justify-content-end align-items-center pt-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleChangeVisibleColumn}
                  >
                    Ẩn/hiện
                  </Button>
                </div>
              </div>
            </Box>
          </Popover>
        </h1>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenAddDialog}
          startIcon={<AddCircle />}
        >
          Thêm dự án
        </Button>
      </div>

      <ListRecordsManagement
        openCreatMapModal={handleOpenCreatMapModal}
        dataModel={dataModel || []}
        dataList={(dataModel && dataModel.items) || []}
        setDataList={setDataModel}
        showConsult={showConsult}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        pageSize={pageSizeDefault}
        setPageSize={setPageSize}
        onGetListData={onGetListPlanning}
        planning={planning}
        setPlanning={setPlanning}
        planningId={planningId}
        setPlanningId={setPlanningId}
        editAction={handleOpenEditDialog}
        deleteAction={handleOpenDeleteDialog}
        totalItemCount={(dataModel && dataModel.totalItemCount) || 0}
        headCells={headCells}
        planningName={planningName}
        setShowConsult={setShowConsult}
      />

      {openAddDialog && (
        <AddRecordsManagement
          isOpen={openAddDialog}
          onClose={handleCloseAddDialog}
          onSuccess={onSuccessAdd}
          cgisId={cgisId}
        />
      )}

      {openEditDialog && (
        <EditRecordsManagement
          isOpen={openEditDialog}
          planningId={planningId}
          onClose={handleCloseEditDialog}
          onSuccess={onSuccessEdit}
          cgisId={cgisId}
        />
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={onDeletePlanning}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
        />
      )}
      {/* Create map modal */}
      <CreatMapView
        open={openCreatMapModal}
        mapId={mapIdSelected}
        planningId={planningIdSelected}
        handleClose={() => handleCloseCreatMapModal()}
        planningName={planningNameSelected}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RecordsManagement);
